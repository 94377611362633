import MyPage from '../module/MyPage.js';


export default class About extends MyPage {

	constructor() {

		super();

		this.name = 'about';

	}

}
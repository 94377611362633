import PubSub      from 'pubsub-js';
import anime       from 'animejs/lib/anime.es.js';

import Console     from './common/Console.js';
import Util        from './common/Util.js';
import Loading     from './common/Loading.js';
import Preloading  from './common/Preloading.js';
import Navigation  from './Navigation.js';
import Top         from './common/Top.js';

import About       from './page/About.js';
import Actualities from './page/Actualities.js';
import Actuality   from './page/Actuality.js';
import Culture     from './page/Culture.js';
import Home        from './page/Home.js';
import Legal       from './page/Legal.js';
import Product     from './page/Product.js';
import Tool        from './page/Tool.js';


export default class Transition {

	constructor() {

		this.firstRun = true;

		this.isPromise = false;

		this.classesIncludes =
		{
			'About'       : About,
			'Actualities' : Actualities,
			'Actuality'   : Actuality,
			'Culture'     : Culture,
			'Home'        : Home,
			'Legal'       : Legal,
			'Product'     : Product,
			'Tool'        : Tool
		};

	}

	/*
	** load
	*/

	load (page, request) {

		Console.log('Transition::load('+ page +','+ request.path +')');

		// promise
		if (this.isPromise)
		{
			return false;
		}

		// first run
		if (this.firstRun)
		{
			// current
			this.currentRequest = request; 

			// build page
			this.build(page);
		}
			else if ( Util.cleanPath(this.currentRequest.path) !== Util.cleanPath(request.path) )
		{
			// referer
			this.referer = Util.cleanPath(this.currentRequest.path);

			// current
			this.currentRequest = request; 

			// loading
			Loading.show();

			// leave
			this.isPromise = true;

			this.leave().then(() =>
			{
				// get
				this.get(request.path).then(response =>
				{
					// unload
					this.unload();

					// container
					this.container.innerHTML = response.getElementById('container').innerHTML;

					// scrollTop
					Util.scrollToTop();

					// build
					this.build(page);

					// promise
					this.isPromise = false;

				}).catch(message => 
				{
					Console.log('Transition::load error ('+ message +')');

					this.isPromise = false;
				});
			});

		}

	}

	get (url) {

		Console.log('Transition::get('+ url +')');

		return new Promise( (resolve, reject) =>
		{
			let xhr = new window.XMLHttpRequest();

			xhr.onreadystatechange = () =>
			{
				if (xhr.readyState === 4)
				{
					if (xhr.status === 200)
					{
						resolve(xhr.responseXML);
					}
					 else
					{
						reject(xhr);
					}
				}
			};

			xhr.open('GET', url, true);
			xhr.responseType = 'document';
			xhr.send();
		});

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Transition::unload');

		if (this.pageInstance !== undefined)
		{
			this.pageInstance.unload();

			this.pageInstance = undefined;
		}

	}

	/*
	** build
	*/

	buildFirst() {

		Console.log('Transition::buildFirst');

		let page = document.getElementById('section').getAttribute('data-page');

		this.build(page);

	}

	build (page) {

		Console.log('Transition::build('+ page +')');

		// current
		this.currentPage = page;

		// section
		let section = document.getElementById('section');

		// title
		document.title = section.getAttribute('data-title');

		// navigation
		let controller = section.getAttribute('data-controller');

		Navigation.setActive(controller);

		// alt
		Util.removeAlt('.section');

		// blank
		Util.addBlank('.wysiwyg');

		// page
		let className = Util.capitalize(page);
		this.pageInstance = new this.classesIncludes[className]();
		this.pageInstance.load();

	}

	/*
	** enter
	*/

	enter() {

		Console.log('Transition::enter');

		// tween 
		anime({
			targets  : this.container,
			opacity  : 1,
			easing   : 'easeOutQuint',
			duration : 600,
			complete : anim => this.pageInstance.enter()
		});

		// class
		document.body.classList.remove('body--transition');

		// loading
		Loading.hide();

		// top
		this.top.update();

	}

	/*
	** leave
	*/

	leave() {

		Console.log('Transition::leave');

		// page leave
		if (this.pageInstance !== undefined)
		{
			this.pageInstance.leave();
		}

		// class
		document.body.classList.add('body--transition');

		// return
		return new Promise((resolve, reject) =>
		{
			anime({
				targets  : this.container,
				opacity  : 0,
				easing   : 'easeOutQuint',
				duration : 600,
				complete : () => resolve()
			});
		});

	}

	/*
	** start
	*/

	start() {

		Console.log('Transition::start');

		// container
		this.container = document.getElementById('container');

		// top
		this.top = new Top();
		this.top.load();

		// subscribe
		PubSub.subscribe('page.ready', (msg, data) =>
		{
			if (this.firstRun)
			{
				Preloading.hide(() =>
				{
					this.firstRun = false;

					this.pageInstance.enter();
				});
			}
			 else
			{
				this.enter();
			}
		});

	}

}

export default class Newsletter {

	constructor() {

		this.DOM = {};

		this.DOM.body = document.body;

	}

	/*
	** open / close
	*/

	open() {

		// class
		this.DOM.body.classList.add('body--newsleter-opened');

	}

	close() {

		// class
		this.DOM.body.classList.remove('body--newsleter-opened');

	}

	/*
	** load
	*/

	load() {

		this.DOM.button = document.getElementById('newsletter-button');

		if (this.DOM.button === null)
		{
			return false;
		}

		this.DOM.container = document.getElementById('newsletter');

		/* button */
		this.DOM.button.addEventListener('click', event =>
		{
			this.open();

			event.preventDefault();

		}, false);

		/* close */
		this.DOM.close = this.DOM.container.querySelector('.newsletter__close');

		this.DOM.close.addEventListener('click', event =>
		{
			this.close();

			event.preventDefault();

		}, false);

		/* background */
		this.DOM.background = this.DOM.container.querySelector('.newsletter__background');

		this.DOM.background.addEventListener('click', event =>
		{
			this.close();

			event.preventDefault();

		}, false);

	}

	/*
	** unload
	*/

	unload() {

		//

	}

}
import Page from '../common/Page.js';
import Util from '../common/Util.js';
import Panel from '../module/Panel.js';
import FluidIframes from '../module/FluidIframes.js';
import Newsletter from '../module/Newsletter.js';


export default class Home extends Page {

	constructor() {

		super();

		this.name = 'home';

	}

	/*
	** resize
	*/
	/*
	resize (event) {

		if ( !Util.isSmallWidth() )
		{
			if ( this.isSmallwidth || (this.isSmallwidth == undefined) ) 
			{

				if (this.panel === undefined)
				{
					this.panel = new Panel('panel');
					this.panel.load();
				}

				this.isSmallwidth = false;
			}
		}
		 else
		{
			if ( !this.isSmallwidth || (this.isSmallwidth == undefined) ) 
			{

				if (this.panel !== undefined)
				{
					this.panel.unload();
					this.panel = undefined;
				}


				this.isSmallwidth = true;
			}
		}

	}
	*/

	/*
	** init
	*/

	init() {

		// top
		Util.scrollToTop();

		// super
		super.init();

		// message
		this.message();

		// resize
		this.resize();

		// fluidIframes
		this.fluidIframes = new FluidIframes('.home');
		this.fluidIframes.load();

		// newsletter
		this.newsletter = new Newsletter();
		this.newsletter.load();

	}

	message () {

		const messageEl = this.container.querySelector('.message');

		messageEl.style.cursor = 'pointer';

		messageEl.addEventListener('click', event =>
		{
			document.body.classList.add('body--newsleter-opened');

			event.preventDefault();

		}, false);

		/* close */
		const closeEl = this.container.querySelector('.message__close');

		closeEl.addEventListener('click', event =>
		{
			messageEl.style.display = 'none';

			event.stopPropagation();

			event.preventDefault();

		}, false);

	}

	/*
	** unload
	*/

	unload() {

		// panel
		/*
		if (this.panel === undefined)
		{
			this.panel.unload();
			delete this.panel;
		}
		*/

		// fluidIframes
		this.fluidIframes.unload();

		// newsletter
		this.newsletter.unload();

		// super
		super.unload();

	}

}
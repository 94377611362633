import { createRouter } from 'routerjs';

import Path       from './common/Path.js';
import Console    from './common/Console.js';
import Navigation from './Navigation.js';
import Transition from './Transition.js';


export default class App {

	constructor (baseUrl = '', debug = false) {

		Path.setBaseUrl(baseUrl);

		Console.setDebug(debug);

		this.isRouter = false;

	}

	/*
	** start
	*/

	start() {

		// navigation
		this.navigation = new Navigation();
		this.navigation.start();

		// transition
		this.transition = new Transition();
		this.transition.start();

		// router
		this.router(this.isRouter);

	}

	/*
	** router
	*/

	router (isRouter=false) {

		if (isRouter)
		{
			// scrollrestauration
			if ('scrollRestoration' in history) 
			{
				history.scrollRestoration = 'manual';
			}

			// router
			const router = createRouter();

			// home
			router.get('/', (request, context) => {
				this.transition.load('home', request);
			});

			router.get('/index.php', (request, context) => {
				this.transition.load('home', request);
			});

			// 
			/*
			router.get('/', (request, context) => {
				this.transition.load('', request);
			});
			*/

			// run
			router.run();

		}
		 else
		{
			this.transition.buildFirst();
		}

	}


}
import PageEvent from './PageEvent.js';
import Util      from './Util.js';


export default class Top extends PageEvent {

	constructor() {

		super();

	}

	/*
	** scrollToTop
	*/

	scrollToTop (event) {

		Util.scrollToElement('body,html', 1000);

	}

	/*
	** resize
	*/

	resize (event) {

		this.update();

	}

	/*
	** scroll
	*/

	scroll (event) {

		this.update();

	}

	/*
	** update
	*/

	update() {

		if (Util.getScrollTop() > 0)
		{
			this.container.classList.remove('top--hidden');
		}
		 else
		{
			this.container.classList.add('top--hidden'); 
		}

	}

	/*
	** load
	*/

	load() {

		// super
		super.load();

		// container
		this.container = document.querySelector('.top');

		// button
		this.button = document.querySelector('.top__button');

		const self = this;

		this.button.addEventListener('click', function(event)
		{
			self.scrollToTop();

			event.preventDefault();

		}, false);

		// update
		this.update();

	}

}

import Console  from '../common/Console.js';
import Swiper, { Navigation, Pagination, Lazy } from 'swiper';


export default class Paragraphs {

	constructor() {

		this.swiperArr = [];

	}

	/*
	** init
	*/

	init () {

		// elements
		this.elements = document.querySelectorAll('.tools__item');

		// count
		this.count = this.elements.length;

		if ( !this.count )
		{
			return false;
		}

		// observer
		var _this = this;

		this.observer = new IntersectionObserver( (entries, observer) =>
		{
			for (let i = 0; i < entries.length; ++i)
			{
				let entry = entries[i];

				if (entry.intersectionRatio > 0)
				{
					let element = entry.target;

					_this.initElement(element);

					observer.unobserve(entry.target);
				}
			}

		});

		// observe
		for (let i = 0; i < this.count; ++i)
		{
			let element = this.elements[i];

			this.observer.observe(element);
		}

	}

	initElement (element) {

		let id = element.getAttribute('data-id');

		let initialized = element.getAttribute('data-initialized')

		if ( !initialized )
		{
			// swiper
			let swiper = new Swiper('#swiper-' + id,
			{
				modules: [Navigation, Pagination, Lazy],

				// slides
				loop: true,
				spaceBetween: 0,
				roundLengths: true,

				// interactivity
				grabCursor: true,
				mousewheel: false,
				preventInteractionOnTransition: false,

				// lazyload
				preloadImages: false,
				lazy:
				{
					loadPrevNext: true,
					loadPrevNextAmount: 1,
					loadOnTransitionStart: true,
				},

				//pagination
				pagination:
				{
					el: '#swiper-' + id + ' .swiper-pagination',
					type: 'bullets',
					clickable: true
				},
			});

			// push
			this.swiperArr.push(swiper);

			// initialized
			element.setAttribute('data-initialized', true);
		}

	}
	/*
	** load
	*/

	load () {

		Console.log('Tools::load');

		// element
		this.element = document.querySelector('.tools');

		if (this.element === null)
		{
			return false;
		}

		// container
		this.containerEl = this.element.querySelector('.tools__container');

		if (this.containerEl === null)
		{
			return false;
		}

		// init
		this.init();

	}

	/*
	** unload
	*/

	unload () {

		Console.log('Tools::unload');

		// swipper
		for (let i = 0; i < this.swiperArr.length; i++)
		{
			let swiperClass = this.swiperArr[i];

			if (swiperClass !== undefined)
			{
				swiperClass.unload();
			}
		}

		// unobserve
		for (let i = 0; i < this.count; ++i)
		{
			let element = this.elements[i];

			this.observer.unobserve(element);
		}
	}

}
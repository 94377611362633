import MyPage from '../module/MyPage.js';


export default class Actualities extends MyPage {

	constructor() {

		super();

		this.name = 'actualities';

	}

}
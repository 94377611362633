import Util from '../common/Util.js';
import Page from '../common/Page.js';
import FluidIframes from './FluidIframes.js';
import Wysiwyg from './Wysiwyg.js';
import Paragraphs from './Paragraphs.js';
import Tools from './Tools.js';
import Newsletter from './Newsletter.js';


export default class MyPage extends Page {

	constructor() {

		super();

	}

	/*
	** init
	*/

	init() {

		// top
		Util.scrollToTop();

		// fluidIframes
		this.fluidIframes = new FluidIframes('.page');
		this.fluidIframes.load();

		// wysiwyg
		this.wysiwyg = new Wysiwyg();
		this.wysiwyg.load();

		// paragraphs
		this.paragraphs = new Paragraphs();
		this.paragraphs.load();

		// tools
		this.tools = new Tools();
		this.tools.load();

		// newsletter
		this.newsletter = new Newsletter();
		this.newsletter.load();

		// super
		super.init();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// fluidIframes
		this.fluidIframes.unload();

		// wysiwyg
		this.wysiwyg.unload();

		// paragraphs
		this.paragraphs.unload();

		// tools
		this.tools.unload();

		// newsletter
		this.newsletter.unload();

	}

}
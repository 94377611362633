import Console   from './common/Console.js';
import PageEvent from './common/PageEvent.js';
import Util      from './common/Util.js';
import Loading   from './common/Loading.js';


export default class Navigation extends PageEvent {

	constructor() {

		super();

	}

	/*
	** setActive
	*/

	static setActive (controller) {

		Console.log('Navigation::setActive('+ controller +')');

	}

	/*
	** init
	*/

	init() {

		// hide
		const flap = this.container.querySelector('.header__flap');

		flap.addEventListener('mouseenter', event => 
		{
			this.container.classList.add('header--hover');

		}, false);

		flap.addEventListener('mouseleave', event => 
		{
			this.container.classList.remove('header--hover');

		}, false);

		// burger
		const burger = this.container.querySelector('.header__burger');

		burger.addEventListener('click', event =>
		{
			if (document.body.classList.contains('body--header-open'))
			{
				document.body.classList.remove('body--header-open');
			}
			 else
			{
				document.body.classList.add('body--header-open');
			}

		}, false);

	}

	/*
	** scroll
	*/

	scroll (event) {

		if ( Util.getScrollTop() >= 10 )
		{
			document.body.classList.add('body--scrolled-start');
		}
		else
		{
			document.body.classList.remove('body--scrolled-start');
		}

		if ( Util.getScrollTop() >= (Util.windowHeight()/2) )
		{
			document.body.classList.add('body--scrolled-mid');
		}
		else
		{
			document.body.classList.remove('body--scrolled-mid');
		}

	}

	/*
	** start
	*/

	start() {

		Console.log('Navigation::start');

		// elements
		this.container = document.querySelector('.header');

		// load
		this.load();

		// init
		this.init();

	}

}
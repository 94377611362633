import MyPage from '../module/MyPage.js';


export default class Tool extends MyPage {

	constructor() {

		super();

		this.name = 'tool';

	}

}
import Console  from '../common/Console.js';

export default class Panel {

	constructor (elementId) {

		this.id = elementId;

		this.element = document.getElementById(this.id);

		this.viewport = {width: 0, height: 0};

		this.mouse = {x: 0, y: 0};

		this.margin = {percent: 200, value: 50};

		this.ease = 0.005;

		this.lerp = (a, b, n) => (1 - n) * a + n * b;

		this.boundResize = event => this.resize(event);

		this.boundMousemove = event => this.mousemove(event);

		this.selectedEl = null;

	}

	/*
	** locateToElement
	*/

	locateToElement (el) {

		if (el === undefined)
		{
			el = null;
		}

		this.selectedEl = el;

	}

	/*
	** render
	*/

	render() {

		let position;

		if (this.selectedEl !== null)
		{
			position = this.getNewPositionFromElement();
		}
		 else
		{
			position = this.getNewPositionFromMousePosition();
		}

		// translate
		this.element.style.transform = 'translate3d('+ position.x +'px, '+ position.y +'px, 0)';

		// render
		this.request = requestAnimationFrame(() => this.render());

	}

	getNewPositionFromElement() {

		let rect = this.element.getBoundingClientRect();

		// selected
		let newx = -this.selectedEl.offsetLeft - (this.selectedEl.offsetWidth  /2) + (this.viewport.width   /2);
		let newy = -this.selectedEl.offsetTop  - (this.selectedEl.offsetHeight /2) + ( this.viewport.height /2);

		// position
		let x = this.lerp(rect.left, newx, this.ease);
		let y = this.lerp(rect.top, newy, this.ease);

		return {x: x, y: y};

	}

	getNewPositionFromMousePosition() {

		let rect  = this.element.getBoundingClientRect();

		// percent
		let percentx = Math.round( (this.mouse.x * this.margin.percent) / this.viewport.width ) - this.margin.value;
		let percenty = Math.round( (this.mouse.y * this.margin.percent) / this.viewport.height) - this.margin.value;

		// new position
		let newx = - (percentx * (rect.width  - this.viewport.width )) / 100;
		let newy = - (percenty * (rect.height - this.viewport.height)) / 100;

		// position
		let x = Math.round( this.lerp(rect.left, newx, this.ease) );
		let y = Math.round( this.lerp(rect.top, newy, this.ease) );

		return {x: x, y: y};

	}

	/*
	** mousemove
	*/

	mousemove (event) {

		this.mouse.x = event.clientX;
		this.mouse.y = event.clientY;

	}

	/*
	** resize
	*/

	resize (event) {

		// viewport
		this.viewport.width = document.documentElement.clientWidth;
		this.viewport.height = document.documentElement.clientHeight;

		// mouse
		this.mouse.x = this.viewport.width  / 2;
		this.mouse.y = this.viewport.height / 2;

	}

	/*
	** load
	*/

	load() {

		Console.log('Panel::load');

		// mousemove
		document.addEventListener('mousemove', this.getMousePosition);

		// resize
		window.addEventListener('resize', this.boundResize);
		window.addEventListener('orientationchange', this.boundResize);

		// mousemove
		document.addEventListener('mousemove', this.boundMousemove);

		// resize
		this.resize();

		// render
		this.render();

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Panel::unload');

		// animationframe
		if (this.request !== undefined)
		{
			cancelAnimationFrame(this.request);
			this.request = undefined;
		}

		// resize
		window.removeEventListener('resize', this.boundResize);
		window.removeEventListener('orientationchange', this.boundResize);

		// mousemove
		document.removeEventListener('mousemove', this.boundMousemove);

	}

}
import MyPage from '../module/MyPage.js';


export default class Actuality extends MyPage {

	constructor() {

		super();

		this.name = 'actuality';

	}

}
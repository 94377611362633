import Console  from '../common/Console.js';


export default class Wysiwyg {

	constructor() {

	}

	/*
	** init
	*/

	init (element) {

		const _this = this;

		const divs = element.querySelectorAll('div');

		_this.close(element);

		for (let i = 0; i < divs.length; ++i)
		{
			let div = divs[i];

			let label = div.querySelector('h3');

			if (label !== null)
			{
				label.addEventListener('click', function(event)
				{
					_this.close(element);

					div.classList.remove('closed');

				}, false);
			}

		}

	}

	/*
	** close
	*/

	close (element) {

		const divs = element.querySelectorAll('div');

		for (let i = 0; i < divs.length; ++i)
		{
			let div = divs[i];

			div.classList.add('closed');
		}

	}

	/*
	** load
	*/

	load () {

		Console.log('Wysiwyg::load');

		const _this = this;

		const elements = document.querySelectorAll('.wysiwyg');

		for (let i = 0; i < elements.length; ++i)
		{
			let element = elements[i];

			_this.init(element);
		}

	}

	/*
	** unload
	*/

	unload () {

		Console.log('Wysiwyg::unload');

	}

}
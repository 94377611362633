import Console from './Console.js';


export default class Preloading {

	/*
	** show
	*/

	static show() {

		Console.log('Preloading::Show');

	}

	/*
	** hide
	*/

	static hide (callback) {

		Console.log('Preloading::hide');

		// hide
		document.querySelector('.preloader').classList.add('preloader--hidden');

		// callback
		if (callback && typeof(callback) === 'function') 
		{
			callback();
		}

	}

}

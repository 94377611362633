import Masonry  from 'masonry-layout';
import Console  from '../common/Console.js';


export default class Paragraphs {

	constructor() {

	}

	/*
	** masonry
	*/

	createMasonry() {

		if (this.msry !== undefined)
		{
			return false;
		}

		this.msry = new Masonry(this.containerEl,
		{
			itemSelector       : '.paragraphs__item',
			columnWidth        : 1,
			gutter             : 0,
			transitionDuration : 0,
			percentPosition    : true
		});

	}

	destroyMasonry() {

		if (this.msry !== undefined)
		{
			this.msry.destroy();

			this.msry = undefined;
		}

	}

	/*
	** load
	*/

	load () {

		Console.log('Paragraphs::load');

		// element
		this.element = document.querySelector('.paragraphs');

		if (this.element === null)
		{
			return false;
		}

		// container
		this.containerEl = this.element.querySelector('.paragraphs__container');

		if (this.containerEl === null)
		{
			return false;
		}

		// masonry
		this.createMasonry();

	}

	/*
	** unload
	*/

	unload () {

		Console.log('Paragraphs::unload');

		// masonry
		this.destroyMasonry();

	}

}